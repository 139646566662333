#root .header {
    padding-left: 0px;
    padding-right: 0px;
}

#root .nav-pills > .nav-item > a {
    color: rgb(10,10,10);
}

#root .nav-pills > .nav-item > a.active {
    background: rgba(200,200,200,0.4);
}
