.profile {
	margin-bottom: 15px;
}

@media (max-width: 575.98px) {
	.row .col-12 .profile {
		display: block;
		margin-left: auto;
		margin-right: auto;
		width: 50%;
	}
}