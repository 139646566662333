html, body {
    background-color: white;
    color: #202020;
    height: 100%;
    font-family: 'Lato', sans-serif;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.container {
    margin-bottom: 15px;
    margin-top: 15px;
}

div.container-fluid {
    max-width: 1200px;
}

.media-heading {
    font-size: 24px;
}

p {
    font-size: 18px;
}

.container-iframe {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}